import React, { FC } from 'react';

interface Props {
  style?: React.CSSProperties;
}

const ContentSection: FC<Props> = props => {
  const { children, style } = props;
  return (
    <section
      style={{
        maxWidth: 'var(--max-width)',
        margin: 'auto',
        padding: 'var(--spacer)',
        ...style,
      }}
    >
      {children}
    </section>
  );
};

export default ContentSection;
