import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ArticleCard from '../components/article-card';
import ContentSection from '../components/content-section';
import FluidSection from '../components/fluid-section';

interface Props {
  data: {
    seoCover: any;
    markdownRemark: any;
    site: {
      siteMetadata: {
        title: string;
        list: null | boolean;
      };
    };
  };
  pageContext: any;
  location: Location;
}

const BlogPostTemplate: FC<Props> = ({ data, pageContext }) => {
  const post = data.markdownRemark;
  const { previous, next } = pageContext;

  return (
    <Layout>
      <SEO
        metaImage={data.seoCover.childImageSharp.fixed}
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article>
        <ContentSection>
          <header>
            <h1>{post.frontmatter.title}</h1>
          </header>
        </ContentSection>
        {post.frontmatter.cover_photo && (
          <>
            <FluidSection style={{ position: 'relative' }}>
              <Image
                style={{ maxHeight: '450px' }}
                fluid={post.frontmatter.cover_photo.childImageSharp.fluid}
              />
              <div
                style={{
                  position: 'absolute',
                  bottom: 0,
                  width: '100%',
                  backgroundColor: 'rgba(0, 0, 0, .6)',
                  color: 'rgb(255, 255, 255)',
                }}
              >
                <div
                  style={{
                    maxWidth: 'var(--max-width)',
                    margin: 'auto',
                    padding: 'var(--spacer)',
                  }}
                >
                  {post.frontmatter.attribution && (
                    <small
                      style={{
                        display: 'block',
                        fontSize: 'var(--small-font-size)',
                      }}
                    >
                      {post.frontmatter.attribution}
                    </small>
                  )}
                  {post.frontmatter.description}
                </div>
              </div>
            </FluidSection>
          </>
        )}
        <ContentSection>
          <small>Posted {post.frontmatter.date}</small>
        </ContentSection>
        <ContentSection>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </ContentSection>
      </article>

      {(previous || next) && (
        <>
          <ContentSection
            style={{
              marginBottom: '1rem',
            }}
          >
            <header>
              <h3>Additional Reading</h3>
            </header>
          </ContentSection>
          <ContentSection style={{ marginBottom: '1rem' }}>
            <ul
              style={{
                display: 'flex',
                alignItems: 'stretch',
                justifyContent: 'space-between',
                listStyleType: 'none',
                margin: 0,
                padding: 0,
              }}
            >
              <li>{previous && <ArticleCard node={previous} />}</li>
              <li>{next && <ArticleCard node={next} />}</li>
            </ul>
          </ContentSection>
        </>
      )}
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    seoCover: file(relativePath: { eq: "assets/profile-pic.jpg" }) {
      childImageSharp {
        fixed(height: 1200, width: 1200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        list
        attribution
        cover_photo {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`;
