import React, { FC } from 'react';

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
>;

const FluidSection: FC<Props> = props => {
  const { children, style } = props;
  return (
    <section
      style={{
        maxWidth: '100%',
        margin: 'auto',
        ...style,
      }}
    >
      {children}
    </section>
  );
};

export default FluidSection;
