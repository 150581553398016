import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';

interface Props {
  node: any;
}

const ArticleCard = (props: Props) => {
  const { node } = props;
  return (
    <article
      style={{
        width: '330px',
        marginBottom: '1.5rem',
        paddingBottom: '1.5em',
      }}
      key={node.fields.slug}
    >
      <Image
        style={{
          borderRadius: '1.5rem',
        }}
        fixed={node.frontmatter.cover_photo.childImageSharp.fixed}
      />
      <header>
        <small>Published {node.frontmatter.date}</small>
        <h3>
          <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
        </h3>
      </header>
      <section>
        <p
          dangerouslySetInnerHTML={{
            __html: node.frontmatter.description || node.excerpt,
          }}
        />
      </section>
    </article>
  );
};

export default ArticleCard;
